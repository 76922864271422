<template>
  <div class="boxUs">
    <el-row class="dash-board">
      <el-button type="primary" @click="getOne" v-permission="['Users:select']" class="inquire">查询</el-button>
      <el-button type="warning" @click="reset" class="reset">重置</el-button>
      <el-button type="primary" @click="adduser" v-permission="['Jurisdiction:add']" class="adduser">+添加管理员\班主任</el-button>
      <p class="zhuangtai" style="font-size: 12px">状态:</p>
      <p class="time" style="font-size: 12px">角色名称:</p>
      <p class="name" style="font-size: 12px">用户账号:</p>
      <el-input v-model="userName" class="in2" placeholder="请输入用户账号"></el-input>
      <!-- 归属角色 -->
      <el-select v-model="roleName" class="data">
        <el-option lable="" v-for="(i,k) in roleNameList" :key="k" :value="i.roleName"></el-option>
      </el-select>
      <!-- 状态 -->
      <el-select v-model="activeFlag" class="opst3">
        <el-option v-for="(i) in statelist" :key="i.value" :value="i.id" :label="i.value"></el-option>
      </el-select>
    </el-row>
    <!-- 列表数据 -->
    <el-row class="bg">
    <el-table
      :data="tableData"
      style="width: 100%;">
      <el-table-column
        label="用户账号"
        align="center"
        width="250">
        <template slot-scope="scope">
          <span>{{ scope.row.loginName }}</span>
        </template>
      </el-table-column>
       <el-table-column
        label="姓名"
        align="center"
        width="250">
        <template slot-scope="scope">
          <span>{{ scope.row.userName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="角色名称"
        align="center"
        width="250">
        <template slot-scope="scope">
          <span>{{ scope.row.roleName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        align="center"
        width="250">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.activeFlag == 1">已启用</el-tag>
          <el-tag type="danger" v-if="scope.row.activeFlag == 2">已禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" v-permission="['Users:edit']" @click="handleEdit(scope.row.userId)">修 改</el-button>
          <el-button size="mini" type="danger" v-permission="['Users:delete']" @click="userDelete(scope.row.userId)">删 除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-row>
    <!-- 新增用户 -->
    <el-dialog
      title="新增用户"
      :visible.sync="adduserVisible"
      width="700"
      center>
      <el-form
        :model="ruleForm2"
        status-icon
        :rules="rules2"
        ref="ruleForm2"
        label-width="0"
        class="demo-ruleForm"
      >
        <!-- <el-form-item prop="username" label="用户账号" :label-width="formLabelWidth">
          <el-input v-model="ruleForm2.username" auto-complete="off" placeholder="请输入11位用户账号"></el-input>
        </el-form-item> -->
        <el-form-item prop="userName" label="姓名" :label-width="formLabelWidth" v-if="ruleForm2.roleName == '班主任' ">
          <el-input v-model="ruleForm2.userName" auto-complete="off" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机号" :label-width="formLabelWidth">
          <el-input v-model="ruleForm2.phone" auto-complete="off" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item prop="roleName" label="角色名称" :label-width="formLabelWidth">
          <el-select v-model="ruleForm2.roleName" placeholder="请选择角色名称">
            <el-option lable="" v-for="(i,k) in roleNameList" :key="k" :value="i.roleName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="pass" label="密码" :label-width="formLabelWidth">
          <el-input type="password" v-model="ruleForm2.pass" auto-complete="off" placeholder="输入密码必须由小写字母、大写字母、数字、特殊符号"></el-input>
        </el-form-item>
        <el-form-item prop="checkPass" label="确认密码" :label-width="formLabelWidth">
          <el-input type="password" v-model="ruleForm2.checkPass" auto-complete="off" placeholder="确认密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="adduserVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm2')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改操作 -->
    <el-dialog
      title="修改用户信息"
      :visible.sync="centerDialogVisible"
      width="700"
      center>
      <el-form :model="form" ref="form" :rules="rules3">
        <el-form-item label="角色名称" prop="roleName" :label-width="formLabelWidth">
          <el-select v-model="form.roleName" placeholder="请选择角色名称">
            <el-option lable="" v-for="(i,k) in roleNameList" :key="k" :value="i.roleName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="userName" label="姓名" :label-width="formLabelWidth" v-if="form.roleName == '班主任' ">
          <el-input v-model="form.userName" auto-complete="off" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="activeFlag" :label-width="formLabelWidth">
          <el-select v-model="form.activeFlag" placeholder="请选状态" style="width:100%">
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updUser('form')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分页 -->
    <el-row style="margin-top:20px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="pag">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex'
  import { userManageList, userManageModify, updUserByAdmin, delUser, addUserAdmin, roleList } from '@/api/training.js'
  export default {
    name: 'Leavefer',
    data () {
      // const checkName = (rule, value, callback) => {
      //   if (value === '') {
      //     callback(new Error('不能为空'))
      //   } else {
      //     callback()
      //   }
      // }
      const checkphone = (rule, value, callback) => {
        if (value.length !== 11) {
          callback(new Error('请输入正确的手机号码'))
        } else {
          callback()
        }
      }
      // const roleName = (rule, value, callback) => {
      //   if (value === '') {
      //     callback(new Error('不能为空'))
      //   } else {
      //     callback()
      //   }
      // }
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码必须由小写字母、大写字母、数字、特殊符号'))
        } else {
          if (this.ruleForm2.checkPass !== '') {
            this.$refs.ruleForm2.validateField('checkPass')
          }
          callback()
        }
      }
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.ruleForm2.pass) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        tableData: [],
        id: '',
        search: {},
        stuName: '',
        userName: '',
        roleName: '',
        activeFlag: '',
        form: {
          // stuName: '',
          roleName: '',
          activeFlag: ''
        },
        input: '',
        title: '填写请假信息',
        reapplyfor: false,
        formLabelWidth: '100px',
        centerDialogVisible: false,
        dictionaryList4: [],
        statelist: [
          { id: 1, value: '已启用' },
          { id: 2, value: '已禁用' }
        ],
        rolelist: [],
        adduserVisible: false,
        ruleForm2: {
          // username: '',
          pass: '',
          checkPass: '',
          smscode: '',
          phone: '',
          region: ''
        },
        rules2: {
          username: [{
            message: '请输入11位数字用户名',
            trigger: 'blur',
            required: true
          }],
          userName: [{
            trigger: 'blur',
            required: true
          }],
          pass: [{
            validator: validatePass,
            trigger: 'blur',
            required: true
          }],
          checkPass: [{
            validator: validatePass2,
            trigger: 'blur',
            required: true
          }],
          phone: [{
            validator: checkphone,
            trigger: 'blur',
            required: true
          }],
          smscode: [{
            trigger: 'change'
          }],
          roleName: [{
            message: '请选择角色名称',
            trigger: 'change',
            required: true
          }]
        },
        rules3: {
          roleName: [
            { required: true, message: '请选择角色名称', trigger: 'change' }
          ],
          activeFlag: [
            { required: true, message: '请选择状态', trigger: 'change' }
          ]
        },
        roleNameList: [],
        total: 0,
        pageNum: 1,
        pageSize: 10
      }
    },
    mounted () {
      this.getOne()
      this.roleList()
    },
    methods: {
      ...mapMutations({
        saveUser: 'SAVE_USER'
      }),
      roleList() { // 获取角色列表
        roleList().then(res => {
          console.log(res.data)
          if (res.data.code === '200') {
            this.roleNameList = res.data.data
          }
        })
      },
      // 用户信息列表-修改按钮
      handleEdit (userId) {
        this.id = userId
        this.centerDialogVisible = true
        userManageModify(this.id).then(res => {
          this.form = res.data.data[0]
        })
      },
      // 修改用户信息-提交
      updUser(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            const query = {
              id: this.id,
              roleName: this.form.roleName,
              activeFlag: this.form.activeFlag,
              userName: this.form.userName
            }
            updUserByAdmin(query).then(rsp => {
              this[form] = {}
              if (rsp.data.code === '200') {
                this.$notify({
                  title: '成功',
                  message: '修改成功。',
                  type: 'success'
                })
                this.centerDialogVisible = false
                this.getOne()
              }
            })
          }
        })
      },
      // 用户信息-删除
      userDelete (userId) {
        this.id = userId
        this.$confirm('确定删除此用户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delUser(this.id).then(res => {
            if (res.data.code === '200') {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getOne()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
      adduser() {
        this.adduserVisible = true
      },
      // 新增用户
      submitForm (ruleForm2) {
        this.$refs[ruleForm2].validate(valid => {
          if (valid) {
            const from = {
              passWord: this.ruleForm2.checkPass,
              phone: this.ruleForm2.phone,
              userName: this.ruleForm2.userName,
              roleName: this.ruleForm2.roleName,
              loginName: this.ruleForm2.phone
            }
            addUserAdmin(
              from
            ).then((resp) => {
              this[ruleForm2] = {}
              if (resp.data.code === '200') {
                this.$notify({
                  title: '成功',
                  message: '新增成功。',
                  type: 'success'
                })
                this.getOne()
                this.adduserVisible = false
              } else {
                this.$notify({
                  // title: '错误',
                  message: resp.data.msg,
                  type: 'error'
                })
              }
            })
          }
        })
      },
      // 查询
      getOne () {
        this.search = {
          loginName: this.userName,
          roleName: this.roleName,
          activeFlag: this.activeFlag,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        userManageList(this.search).then(res => {
          if (res.data.code === '200') {
            this.tableData = res.data.data.rows
            this.total = res.data.data.total // 查询总条数
            console.log(this.tableData)
          }
        })
      },
      // 清空
      reset () {
        this.userName = ''
        this.roleName = ''
        this.activeFlag = ''
      },
      // 分页
      handleSizeChange(e) {
        this.pageSize = e
        this.getOne()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.getOne()
      }
    }
  }
</script>

<style scoped>
  .boxUs{margin-top: -10px}
  .dash-board {
    display: flex;
    background-color: white;
    height: 120px;
  }
.bg{margin-top: 15px; padding: 20px; background-color: white;}
  .inquire{
    position: absolute;
    top: 70px;
    left:610px;
  }
  .reset{
    position: absolute;
    top: 70px;
    left: 665px;
  }
  .in1{
    width: 150px;
    position: absolute;
    top: 88px;
    left: 290px;
  }
  .in2{
    width: 215px;
    position: absolute;
    top: 20px;
    left: 90px;
  }
  .in3{
    width: 150px;
    position: absolute;
    top: 120px;
    left: 250px;
  }
  .in4{
    width: 150px;
    position: absolute;
    top: 120px;
    left: 480px;
  }
  .name{
    font-size: 15px;
    position: absolute;
    top: 15px;
    left: 20px;
  }
  .one{
    font-size: 15px;
    position: absolute;
    top: 88px;
    left: 470px;
  }
  .iocpn{
    font-size: 15px;
    position: absolute;
    top: 115px;
    left: 210px;
  }
  .two{
    font-size: 15px;
    position: absolute;
    top: 115px;
    left: 425px;
  }
  .time{
    font-size: 15px;
    position: absolute;
    top: 65px;
    left: 20px;
  }
  .data{
    position: absolute;
    top: 70px;
    left: 90px;
  }
  .zhuangtai{position: absolute; top:15px; left: 350px}
  .opst3{ position: absolute; top:20px;left: 390px}
  .adduser{ position: absolute; top: 70px; left: 730px;}
</style>
