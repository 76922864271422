import request from '@/utils/request'
/**
 * 查询考核管理
 * @param trainClass
 * @constructor
 */
export function attendSel (query) {
  return request({
    url: '/attendance/get',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 根据主键id查询字典表
 */
export function GetAllById (id) {
  return request({
    url: `/dev-api/getAllById?id=${id}`,
    method: 'get',
    params: id
  })
}
/**
 * 请假管理列表
 */
export function getAllleaveList (query) {
  return request({
    url: '/leave/get',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 请假管理审核通过
 * @param id
 * @param status
 * @param noAuth
 */
export function auth (leave) {
  return request({
    url: '/leave/upLeaveHead?',
    method: 'post',
    data: leave
  })
}
/**
 * 请假管理审核不通过
 * @param id
 * @param status
 * @param noAuth
 */
export function noAuth (id, status, opinion) {
  return request({
    url: `/leave/updateLeave?&id=${id}&status=${status}&opinion=${opinion}`,
    method: 'get'
  })
}
/**
 * 培训管理列表
 */
export function AuthenticateList (query) {
  return request({
    url: '/beihaieducation/authenticate/selectAuth',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 培训管理列表-班委鉴定
 */
export function appraisal (query) {
  return request({
    url: '/beihaieducation/authenticate/insBworBzr',
    method: 'post',
    data: query
  })
}
/**
 * 培训管理列表-鉴定详情
 */
export function getAppraisalDetail (id) {
  return request({
    url: '/beihaieducation/authenticate/detail/' + id,
    method: 'get',
    params: id
  })
}
/**
 * 培训管理列表-单位鉴定
 * @param id
 * @param OperationType
 * @param opinionText
 */
export function opinion (query) {
  return request({
    url: '/beihaieducation/authenticate/insBworBzr',
    method: 'post',
    data: query
  })
}
/**
 * 学员-日程安排
 *  */
export function scheduleByUsername (query) {
  return request({
    url: '/bh/student/scheduleByUsername',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 学员培训评估-列表
 */
export function assessList (query) {
  return request({
    url: '/assess/getAssessStu',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 学员课程评估-列表
 */
export function getCourseList (query) {
  return request({
    url: '/bh/stu/courseAssess/findStuCourseAssess',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 学员培训评估-查看
 */
export function details (id) {
  return request({
    url: '/assess/detail?id=' + id,
    method: 'get'
  })
}
/**
 * 学员培训评估-提交
 */
export function assessmentNoAuth (assess) {
  return request({
    url: '/assess/UpdAssessStuById',
    method: 'post',
    data: assess
  })
}
/**
 * 学员课程评估-提交
 */
export function addCourse (data) {
  return request({
    url: '/bh/stu/courseAssess/commit',
    method: 'post',
    data: data
  })
}
/**
 * 学员培训评估-批改作业查看
 */
export function WorkCorrection (query) {
  return request({
    url: '/stuWork/insWorkGrade',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 学员课程评估-查看
 */
export function getCourseDetail (id) {
  return request({
    url: '/bh/stu/courseAssess/detail/' + id,
    method: 'get'
  })
}
/**
 * 学员培训评估-上传附件
 */
export function upload (formData) {
  return request({
    url: '/upload/admin',
    method: 'post',
    data: formData
  })
}
/**
 * 学员培训评估-作业提交
 */
export function submitWork (query) {
  return request({
    url: '/stu/stuWork/submitWork',
    method: 'post',
    data: query
  })
}
/**
 * 学员培训评估-作业列表(不要了)
 */
export function workListByStu (query) {
  return request({
    url: '/stuWork/workListByStu',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 学员培训评估-作业列表（新的）
 */
 export function findStuClass (query) {
  return request({
    url: '/stu/stuWork/findStuClass',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 学员培训评估-作业列表某个班级里的作业列表（新的）
 */
 export function findSubmitWork (query) {
  return request({
    url: '/stu/stuWork/findSubmitWork',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 学员培训评估-作业列表某个班级里的作业列表（修改）
 */
 export function editWork (query) {
  return request({
    url: '/stu/stuWork/editWork',
    method: 'put',
    data: query
  })
}
/**
 * 管理员培训评估列表
 */
export function getAdminAssessStu (query) {
  return request({
    url: '/assess/getAdminAssessStu',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 管理员课程评估详情
 */
export function getAdminCourseDetail (id) {
  return request({
    url: '/bh/admin/courseAssess/detail/' + id,
    method: 'get'
  })
}
/**
 * 管理员课程评估列表
 */
export function getAdminCourseList (query) {
  return request({
    url: '/bh/admin/courseAssess/findCourseAssess',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 管理员-批改作业列表
 */
export function getAdminWorkList (query) {
return request({
url: '/stuWork/workList',
method: 'get',
params: {
...query
}
})
}
/**
 * 管理员-批改作业列表
 */
export function findClassList (query) {
  return request({
    url: '/stuWork/findClass',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 管理员-批改作业列表----班级学员列表
 */
 export function findStuWork (query) {
  return request({
    url: '/stuWork/findStuWork',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 管理员-批改作业列表----班级学员列表---学员作业列表
 */
 export function adminfindSubmitWork (query) {
  return request({
    url: '/stuWork/findSubmitWork',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 管理员-批改作业列表----班级学员列表---学员作业列表--打分
 */
 export function modifyWorkGrade (query) {
  return request({
    url: '/stuWork/modifyWorkGrade',
    method: 'post',
    data: query
  })
}
/**
 *总后台审批请假
 */
export function upLeaveZzb (zzB) {
  return request({
    url: '/leave/upLeaveZzb',
    method: 'post',
    data: zzB
  })
}
/**
 * 管理员-评分列表
 *  */
export function comment (query) {
  return request({
    url: '/assess/getAvgList',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 管理员-用户管理
 *  */
export function userManageList (query) {
  return request({
    url: '/dev-api/getUserAll',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 管理员-用户管理-新增用户
 *  */
export function addUserAdmin (insUserByAdmin) {
  return request({
    url: '/user/insUserByAdmin',
    method: 'post',
    data: insUserByAdmin
  })
}
/**
 * 管理员-用户管理-修改
 *  */
export function userManageModify (id) {
  return request({
    url: '/dev-api/updUserByAdmin',
    method: 'get',
    params: {
      id
    }
  })
}
/**
 * 管理员-用户管理-修改-提交
 *  */
export function updUserByAdmin (query) {
  return request({
    url: '/dev-api/updUserByAdmins',
    method: 'post',
    data: query
  })
}
/**
 * 管理员-用户管理-删除
 *  */
export function delUser (id) {
  return request({
    url: '/dev-api/delUserByAdmin',
    method: 'get',
    params: {
      id
    }
  })
}
/**
 * 管理员-权限管理-角色列表
 *  */
export function roleList () {
  return request({
    url: '/bh/sys-role/findRoleAll',
    method: 'get'
  })
}
/**
 * 管理员-权限管理-获取
 *  */
export function roleUrlById (id) {
  return request({
    url: '/bh/sys-role/roleUrlById',
    method: 'get',
    params: {
      id
    }
  })
}
/**
 * 管理员-权限管理-修改
 *  */
export function updUrlByAdmin (updSysAuth) {
  return request({
    url: '/beihaieducation/sys-auth/updUrlByAdmin',
    method: 'post',
    data: updSysAuth
  })
}
/**
 * 管理员-权限管理-添加角色
 *  */
export function addRole (sysRole) {
  return request({
    url: '/bh/sys-role/insSysRole',
    method: 'post',
    data: sysRole
  })
}
/**
 * 管理员-权限管理-修改角色
 *  */
export function selSysRole (id) {
  return request({
    url: '/bh/sys-role/selSysRole',
    method: 'get',
    params: {
      id
    }
  })
}
/**
 * 管理员-权限管理-修改提交角色
 *  */
export function editleRole (sysRole) {
  return request({
    url: '/bh/sys-role/updSysRole',
    method: 'post',
    data: sysRole
  })
}
/**
 * 管理员-权限管理-删除角色
 *  */
export function delRole (id) {
  return request({
    url: '/bh/sys-role/delSysRole',
    method: 'get',
    params: {
      id
    }
  })
}
/**
 * 管理员-数据展示-统计学生人数
 *  */
export function getStudentNum () {
  return request({
    url: '/bh/student/studentNum',
    method: 'get'
  })
}
/**
 * 管理员-数据展示-统计班级
 *  */
export function getClassNum () {
  return request({
    url: '/class/classNum',
    method: 'get'
  })
}
/**
 * 管理员-数据展示-统计性别
 *  */
export function getSexNum () {
  return request({
    url: '/bh/student/sexNum',
    method: 'get'
  })
}
/**
 * 管理员-数据展示-统计年龄
 *  */
export function getBirthNum () {
  return request({
    url: '/bh/student/birthNum',
    method: 'get'
  })
}
/**
 * 管理员-数据展示-统计分数
 *  */
 export function achievement (classId) {
  return request({
    url: '/bh/student/test/statisticsScoreToStuNum',
    method: 'get',
    params: {
      classId
    }
  })
}
/**
 * 管理员-数据展示-统计某个班下面考勤
 *  */
 export function getCountLeave (query) {
  return request({
    url: '/leave/getCountLeave',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 管理员-数据展示-选择班级获取试卷列表
 *  */
 export function getListTestByClassId (classId) {
  return request({
    url: '/bh/student/test/getListTestByClassId',
    method: 'get',
    params: {
      classId
    }
  })
}
/**
 * 管理员-数据展示-学员考试完成率
 *  */
 export function StuTestCompletionRate (query) {
  return request({
    url: '/bh/student/test/StuTestCompletionRate',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 管理员-培训成果-班级总结-列表
 *  */
 export function summaryList (query) {
  return request({
    url: '/class/getListByTeacher',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 管理员-培训成果-班级总结-获取班级列表
 *  */
 export function getclassName (userName) {
  return request({
    url: '/class/getclassName',
    method: 'get',
    params: {
      userName
    }
  })
}
/**
 * 管理员-培训成果-班级总结-提交总结
 *  */
 export function insTeacherZj (query) {
  return request({
    url: '/class/insTeacherZj',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 管理员-培训成果-班级总结-查看
 *  */
 export function seeclassData (classId) {
  return request({
    url: '/class/getTrainById',
    method: 'get',
    params: {
      classId
    }
  })
}
/**
 * 管理员-学员管理-列表
 *  */
 export function studentManageList (query) {
  return request({
    url: '/dev-api/getStudentByAdmin',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 管理员-学员管理-详情
 *  */
 export function getUpdateDetail (id) {
  return request({
    url: '/bh/student/detail/' + id,
    method: 'get'
  })
}
/**
 * 管理员-学员管理-修改
 *  */
 export function getUpdate (data) {
  return request({
    url: '/bh/student/update',
    method: 'post',
    data: data
  })
}
/**
 * 管理员-学员管理-干部层次
 *  */
 export function getAllByIdList (id) {
  return request({
    url: '/dev-api/getAllById?',
    method: 'get',
    params: {
      id
    }
  })
}
/**
 * 管理员-学员管理-职级
 *  */
 export function showTreesByRedis () {
  return request({
    url: '/dev-api/showTreesByRedis',
    method: 'get'
  })
}
/**
 * 管理员-学员管理-单位
 *  */
 export function showTreeByRedis () {
  return request({
    url: '/dev-api/showTreeByRedis',
    method: 'get'
  })
}
/**
 * 管理员-学员管理-重置密码
 *  */
 export function updStuPassword (userId) {
  return request({
    url: '/dev-api/updStuPasswordByAdmin',
    method: 'get',
    params: {
      userId
    }
  })
}
/**
 * 管理员-分类管理-列表
 *  */
 export function Classification (parenId) {
  return request({
    url: `/dev-api/find?&parenId=${parenId}`,
    // url: '/dev-api/find?parenId'= + parenId,
    method: 'get'
  })
}
/**
 * 管理员-单位管理-列表
 *  */
 export function findUnit (query) {
  return request({
    url: '/dev-api/findUnit',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 管理员-单位管理-新增单位名称
 *  */
 export function addUnit (query) {
  return request({
    url: '/dev-api/addUnit',
    method: 'post',
    data: query
  })
}
/**
 * 管理员-单位管理-修改单位
 *  */
 export function updateUnit (data) {
  return request({
    url: '/dev-api/updateUnit',
    method: 'post',
    data: data
  })
}
/**
 * 管理员-单位管理-删除
 *  */
 export function delDel (id) {
  return request({
    url: '/dev-api/del/' + id,
    method: 'get'
  })
}
/**
 * 管理员-单位管理-导入
 *  */
 export function importExcel (file) {
  return request({
    url: '/dev-api/importExcel',
    method: 'post',
    data: file
  })
}

/**
 * 管理员-字典管理-坐标管理--列表
 *  */
 export function getAuthenticateList (query) {
  return request({
    url: '/bh/coordinate/getAuthenticate',
    method: 'get',
    params: query
  })
}
/**
 * 管理员-字典管理-坐标管理--新增坐标点
 *  */
 export function addAuthenticate (data) {
  return request({
    url: '/bh/coordinate/add',
    method: 'post',
    data: data
  })
}
/**
 * 管理员-字典管理-坐标管理--修改坐标点
 *  */
 export function updateAuthenticate (data) {
  return request({
    url: '/bh/coordinate/update',
    method: 'put',
    data: data
  })
}
/**
 * 管理员-字典管理-坐标管理--删除坐标点 可批量
 *  */
 export function delAuthenticate (ids) {
  return request({
    url: '/bh/coordinate/del/' + ids,
    method: 'delete'
  })
}
/**
 * 学员管理-删除学员
 *  */
 export function delStuUserByAdmin (query) {
  return request({
    url: '/dev-api/delStuUserByAdmin',
    method: 'get',
    params: query
  })
}
